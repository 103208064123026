<app-header *ngIf="organizationId">
  <button
    type="button"
    bitButton
    buttonType="secondary"
    (click)="load()"
    class="tw-ml-auto"
    *ngIf="firstLoaded"
    [disabled]="loading"
  >
    <i class="bwi bwi-refresh bwi-fw" [ngClass]="{ 'bwi-spin': loading }" aria-hidden="true"></i>
    {{ "refresh" | i18n }}
  </button>
</app-header>

<bit-container>
  <div class="tabbed-header" *ngIf="!organizationId">
    <!--TODO: Organization and individual should use different "page" components -->
    <h1>{{ "paymentMethod" | i18n }}</h1>
  </div>

  <ng-container *ngIf="!firstLoaded && loading">
    <i
      class="bwi bwi-spinner bwi-spin text-muted"
      title="{{ 'loading' | i18n }}"
      aria-hidden="true"
    ></i>
    <span class="sr-only">{{ "loading" | i18n }}</span>
  </ng-container>
  <ng-container *ngIf="billing">
    <h2>{{ (isCreditBalance ? "accountCredit" : "accountBalance") | i18n }}</h2>
    <p class="text-lg">
      <strong>{{ creditOrBalance | currency: "$" }}</strong>
    </p>
    <p>{{ "creditAppliedDesc" | i18n }}</p>
    <button
      type="button"
      bitButton
      buttonType="secondary"
      (click)="addCredit()"
      *ngIf="!showAddCredit"
    >
      {{ "addCredit" | i18n }}
    </button>
    <app-add-credit
      [organizationId]="organizationId"
      (onAdded)="closeAddCredit(true)"
      (onCanceled)="closeAddCredit(false)"
      *ngIf="showAddCredit"
    >
    </app-add-credit>
    <h2 class="spaced-header">{{ "paymentMethod" | i18n }}</h2>
    <p *ngIf="!paymentSource">{{ "noPaymentMethod" | i18n }}</p>
    <ng-container *ngIf="paymentSource">
      <app-callout
        type="warning"
        title="{{ 'verifyBankAccount' | i18n }}"
        *ngIf="
          forOrganization &&
          paymentSource.type === paymentMethodType.BankAccount &&
          paymentSource.needsVerification
        "
      >
        <p>{{ "verifyBankAccountDesc" | i18n }} {{ "verifyBankAccountFailureWarning" | i18n }}</p>
        <form
          #verifyForm
          class="form-inline"
          (ngSubmit)="verifyBank()"
          [formGroup]="verifyBankForm"
          [appApiAction]="verifyBankPromise"
          ngNativeValidate
        >
          <bit-form-field class="tw-mr-2 tw-w-40">
            <bit-label>{{ "amountX" | i18n: "1" }}</bit-label>
            <input bitInput type="number" step="1" placeholder="xx" formControlName="amount1" />
            <span bitPrefix>$0.</span>
          </bit-form-field>
          <bit-form-field class="tw-mr-2 tw-w-40">
            <bit-label>{{ "amountX" | i18n: "2" }}</bit-label>
            <input bitInput type="number" step="1" placeholder="xx" formControlName="amount2" />
            <span bitPrefix>$0.</span>
          </bit-form-field>
          <button
            type="button"
            bitButton
            buttonType="primary"
            type="submit"
            class="btn-submit"
            [disabled]="verifyForm.loading"
          >
            <i
              class="bwi bwi-spinner bwi-spin"
              title="{{ 'loading' | i18n }}"
              aria-hidden="true"
            ></i>
            <span>{{ "verifyBankAccount" | i18n }}</span>
          </button>
        </form>
      </app-callout>
      <p>
        <i class="bwi bwi-fw" [ngClass]="paymentSourceClasses"></i>
        {{ paymentSource.description }}
      </p>
    </ng-container>
    <button type="button" bitButton buttonType="secondary" [bitAction]="changePayment">
      {{ (paymentSource ? "changePaymentMethod" : "addPaymentMethod") | i18n }}
    </button>
    <p *ngIf="isUnpaid">{{ "paymentChargedWithUnpaidSubscription" | i18n }}</p>
    <ng-container *ngIf="forOrganization">
      <h2 class="spaced-header">{{ "taxInformation" | i18n }}</h2>
      <p>{{ "taxInformationDesc" | i18n }}</p>
      <div *ngIf="!org || loading">
        <i
          class="bwi bwi-spinner bwi-spin text-muted"
          title="{{ 'loading' | i18n }}"
          aria-hidden="true"
        ></i>
        <span class="sr-only">{{ "loading" | i18n }}</span>
      </div>
      <form
        *ngIf="org && !loading"
        #formTax
        (ngSubmit)="submitTaxInfo()"
        [appApiAction]="taxFormPromise"
        ngNativeValidate
      >
        <app-tax-info></app-tax-info>
        <button
          bitButton
          buttonType="primary"
          type="submit"
          class="btn-submit"
          [disabled]="formTax.loading"
        >
          <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
          <span>{{ "save" | i18n }}</span>
        </button>
      </form>
    </ng-container>
  </ng-container>
</bit-container>
