<form [formGroup]="formGroup" [bitSubmit]="submit">
  <bit-dialog dialogSize="default">
    <span bitDialogTitle>
      {{ "twoStepLogin" | i18n }}
      <small class="tw-text-muted">{{ dialogTitle }}</small>
    </span>
    <ng-container bitDialogContent>
      <app-user-verification-form-input
        formControlName="secret"
        ngDefaultControl
        name="secret"
      ></app-user-verification-form-input>
    </ng-container>
    <ng-container bitDialogFooter>
      <button bitButton bitFormButton type="submit" buttonType="primary">
        {{ "continue" | i18n }}
      </button>
      <button bitButton type="button" buttonType="secondary" bitDialogClose>
        {{ "close" | i18n }}
      </button>
    </ng-container>
  </bit-dialog>
</form>
